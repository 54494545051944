exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jp-js": () => import("./../../../src/pages/about.jp.js" /* webpackChunkName: "component---src-pages-about-jp-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-ms-js": () => import("./../../../src/pages/about.ms.js" /* webpackChunkName: "component---src-pages-about-ms-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-hire-me-index-jp-js": () => import("./../../../src/pages/hire-me/index.jp.js" /* webpackChunkName: "component---src-pages-hire-me-index-jp-js" */),
  "component---src-pages-hire-me-index-js": () => import("./../../../src/pages/hire-me/index.js" /* webpackChunkName: "component---src-pages-hire-me-index-js" */),
  "component---src-pages-hire-me-index-ms-js": () => import("./../../../src/pages/hire-me/index.ms.js" /* webpackChunkName: "component---src-pages-hire-me-index-ms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-buku-kira-kira-index-js": () => import("./../../../src/pages/projects/buku-kira-kira/index.js" /* webpackChunkName: "component---src-pages-projects-buku-kira-kira-index-js" */),
  "component---src-pages-projects-fchat-index-js": () => import("./../../../src/pages/projects/fchat/index.js" /* webpackChunkName: "component---src-pages-projects-fchat-index-js" */),
  "component---src-pages-projects-game-impianku-index-js": () => import("./../../../src/pages/projects/game-impianku/index.js" /* webpackChunkName: "component---src-pages-projects-game-impianku-index-js" */),
  "component---src-pages-projects-hacking-blog-index-js": () => import("./../../../src/pages/projects/hacking-blog/index.js" /* webpackChunkName: "component---src-pages-projects-hacking-blog-index-js" */),
  "component---src-pages-projects-ilmu-adalah-kuasa-index-js": () => import("./../../../src/pages/projects/ilmu-adalah-kuasa/index.js" /* webpackChunkName: "component---src-pages-projects-ilmu-adalah-kuasa-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-infografik-sifat-solat-nabi-index-js": () => import("./../../../src/pages/projects/infografik-sifat-solat-nabi/index.js" /* webpackChunkName: "component---src-pages-projects-infografik-sifat-solat-nabi-index-js" */),
  "component---src-pages-projects-isave-index-js": () => import("./../../../src/pages/projects/isave/index.js" /* webpackChunkName: "component---src-pages-projects-isave-index-js" */),
  "component---src-pages-projects-kubus-index-js": () => import("./../../../src/pages/projects/kubus/index.js" /* webpackChunkName: "component---src-pages-projects-kubus-index-js" */),
  "component---src-pages-projects-masukwaktu-index-js": () => import("./../../../src/pages/projects/masukwaktu/index.js" /* webpackChunkName: "component---src-pages-projects-masukwaktu-index-js" */),
  "component---src-pages-projects-nasihat-index-js": () => import("./../../../src/pages/projects/nasihat/index.js" /* webpackChunkName: "component---src-pages-projects-nasihat-index-js" */),
  "component---src-pages-projects-solatapi-index-js": () => import("./../../../src/pages/projects/solatapi/index.js" /* webpackChunkName: "component---src-pages-projects-solatapi-index-js" */),
  "component---src-pages-projects-wordpress-site-index-js": () => import("./../../../src/pages/projects/wordpress-site/index.js" /* webpackChunkName: "component---src-pages-projects-wordpress-site-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blogListTemplate.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-templates-js": () => import("./../../../src/templates/blogTemplates.js" /* webpackChunkName: "component---src-templates-blog-templates-js" */),
  "component---src-templates-note-template-js": () => import("./../../../src/templates/noteTemplate.js" /* webpackChunkName: "component---src-templates-note-template-js" */)
}

