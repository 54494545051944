import { extendTheme } from '@chakra-ui/react';

/** @type {import('@chakra-ui/react').ChakraTheme} */
const theme = {
  styles: {
    global: {
      'html, body': {
        fontFamily: 'Yrsa, Georgia, serif',
        fontSize: '18px',
        color: '#404040',
      },
      'h1, h2, h3, h4, h5, h6': {
        letterSpacing: '0em',
        wordSpacing: '0.14em',
        fontFamily: '"Open Sans", sans-serif',
        fontWeight: 'bold',
        margin: '32px 0 16px 0',
      },
      h1: {
        fontSize: '1.5rem',
      },
      h2: {
        fontSize: '1.25rem',
      },
      h3: {
        fontSize: '1.125rem',
      },
      'h4, h5, h6': {
        fontSize: '1rem',
      },
      'p, li': {
        letterSpacing: '0.04em',
        wordSpacing: '0.1em',
        lineHeight: '1.5rem',
      },
      button: {
        fontFamily: 'Open Sans, sans-serif',
      },
      // For HireMe pages
      '.hire-me a': {
        // textDecoration: none;
        padding: '2px 2px 1px 2px',
        backgroundImage: `linear-gradient(
            transparent 0%,
            transparent 68%,
            #44CC66 68%,
            #44CC66 100%
          ),
          linear-gradient(
            transparent 0%,
            transparent 55%,
            #44CC6677 55%,
            #44CC6677 100%
          )`,
        backgroundSize: '100% 200%',
        backgroundPosition: '0',

        transition: 'background-position 120ms ease-in-out 0s, padding 120ms ease-in-out 0s',

        '&:hover': {
          backgroundImage: `linear-gradient(
            #44CC66 0%,
            #44CC66 100%
          )`,
          backgroundPosition: '0px 100%',
          color: '#fff',
        },
      },
    },
  },
  colors: {
    primary: {
      50: '#F2FDF5',
      100: '#EBFAEF',
      200: '#D7F4DE',
      300: '#AFE9BD',
      400: '#87DE9D',
      500: '#44CC66',
      600: '#2CA049',
      700: '#23803A',
      800: '#1B642E',
      900: '#165024',
    },
    secondary: {
      100: '#FBEAF2',
      200: '#F7D4E5',
      300: '#F3C3DA',
      400: '#E67FB0',
      500: '#D32A7A',
      600: '#BF266F',
      700: '#991E59',
      800: '#6F1640',
      900: '#551131',
    },
  },
  components: {
    Text: {
      baseStyle: {
        marginBottom: '16px',
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'Opens Sans, sans-serif',
      },
    },
  },
};

export default extendTheme(theme);
